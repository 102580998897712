<template>
    <b-modal id="modal-search-file" size="lg" body-class="p-0" hide-footer hide-header static>
        <div class="block block-rounded block-themed block-transparent mb-0">
            <b-form @submit.prevent="">
                <div class="block-header bg-light">
                    <h3 class="block-title">Cerca</h3>
                    <div class="block-options">
                        <b-button type="button" variant="dark" size="sm" class="btn-block"
                                  @click="$bvModal.hide('modal-search-file')">
                            <i class="fa fa-fw fa-times"></i>
                        </b-button>
                    </div>
                </div>
                <div class="block-content font-size-sm">

                    <div class="form-group">
                        <b-input-group>
                            <b-form-input class="form-control-alt" placeholder="Nome del file" v-model="searchWord"
                                          @keydown="search"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="alt-dark" @click="search">
                                    <i class="fa fa-search mr-1"></i> Search
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>

                    <template v-if="files && Object.keys(files).length !== 0">
                        <ul class="list-group">
                            <li v-for="file in files" :key="file.id" class="list-group-item">
                                <b-row>
                                    <b-col class="col-lg-8 col-md-8 col-sm-12">


                                        <a
                                                href="javascript:void(0)"
                                                @click="downLoad(file)">
                                            <i v-if="file.filemime.includes('text')" class="fa fa-fw fa-file-alt"></i>
                                            <i v-else-if="file.filemime.includes('application/vnd.rar') || file.filemime.includes('application/zip') || file.filemime.includes('application/x-7z-compressed')"
                                               class="fa fa-fw fa-file-archive"></i>
                                            <i v-else-if="file.filemime.includes('application/vnd.ms-powerpoint') || file.filemime.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation')"
                                               class="fa fa-fw fa-file-powerpoint"></i>
                                            <i v-else-if="file.filemime.includes('application/vnd.ms-excel') || file.filemime.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')"
                                               class="fa fa-fw fa-file-excel"></i>
                                            <i v-else-if="file.filemime.includes('application/msword') || file.filemime.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')"
                                               class="fa fa-fw fa-file-word"></i>
                                            <i v-else-if="file.filemime.includes('application/pdf')"
                                               class="fa fa-fw fa-file-pdf"></i>
                                            <i v-else-if="file.filemime.includes('application')"
                                               class="fa fa-fw fa-file"></i>
                                            <i v-else-if="file.filemime.includes('audio')"
                                               class="fa fa-fw fa-file-audio"></i>
                                            <i v-else-if="file.filemime.includes('image')"
                                               class="fa fa-fw fa-file-image"></i>
                                            <i v-else-if="file.filemime.includes('video')"
                                               class="fa fa-fw fa-file-video"></i>
                                            {{file.nome}}
                                        </a>

                                    </b-col>
                                    <b-col class="text-right col-lg-4 col-md-4 col-sm-12">
                                        <small>{{sizeHandler(file.size)}} </small>
                                        <b-button-group size="sm">
                                            <b-button variant="alt-secondary"
                                                      @click="download(file)">
                                                <i class="fa fa-fw fa-arrow-alt-circle-down"></i>
                                            </b-button>
                                            <b-button variant="alt-secondary"
                                                      @click="$bvModal.show('modal-edit-file-'+file.id)">
                                                <i class="fa fa-fw fa-pencil-alt"></i>
                                            </b-button>
                                        </b-button-group>
                                    </b-col>
                                </b-row>

                                <!-- Modal Edit File -->
                                <modal-edit-file v-if="file.type === 'file'" :file="file">
                                </modal-edit-file>
                                <!-- END Modal Edit File -->

                            </li>
                        </ul>
                        <p></p>
                    </template>

                </div>
            </b-form>
        </div>
    </b-modal>
</template>

<script>
    import axios from "axios";
    import ModalEditFile from "@/views/Tree/File/ModalEditFile";
    import {EventBus} from "@/main";
    import JSZip from "jszip";
    import JSZipUtils from 'jszip-utils';
    import {saveAs} from 'file-saver';

    export default {
        name: "ModalSearchFile",
        components: {ModalEditFile},
        data() {
            return {
                searchWord: '',
                files: null
            }
        },

        mounted() {

            EventBus.$on(["editedFile", "deletedFile", "assignedFile", "deAssignedFile"], () => {
                this.search();
            });

        },

        methods: {
            search() {
                if (this.searchWord.length >= 2) {
                    var url = this.$store.state.server.ip + 'tree';
                    var token = localStorage.getItem('token');

                    return axios.get(url,
                        {
                            params: {
                                'search': this.searchWord,
                            },

                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        })
                        .then(response => {
                            this.files = response.data.data;
                        })
                        .catch(error => {
                            console.log(error.response)
                        });
                } else {
                    this.files = null;
                }
            },

            download(file) {
                var data = [];
                if (file === 'all') {
                    data = this.files.filter(file => (file.type === 'file')).map(function (file) {
                        return file
                    });
                } else if (file === 'allSelected') {
                    const files = this.files;
                    this.selezione.forEach(function (sel) {
                        files.forEach(function (file) {
                            if (file.id === sel) {
                                data.push(file);
                            }
                        })
                    })

                } else {
                    data.push(file);
                }

                if (data.length > 1) {

                    var zip = new JSZip();
                    var count = 0;
                    var zipFilename = "ToyoFiles.zip";
                    var urls = data;

                    urls.forEach(function (url) {
                        var filename = url.nome;
                        // loading a file and add it in a zip file

                        JSZipUtils.getBinaryContent(url.link, function (err, data) {
                            if (err) {
                                throw err; // or handle the error
                            }
                            zip.file(filename, data, {binary: true});
                            count++;
                            if (count == urls.length) {
                                zip.generateAsync({type: 'blob'}).then(function (content) {
                                    saveAs(content, zipFilename);
                                });
                            }
                        });
                    });
                } else {
                    data.forEach(function (file) {
                        axios({
                            url: file.link,
                            method: 'GET',
                            responseType: 'blob',
                        }).then((response) => {
                            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                            var fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            var nomeDownloadFile = file.nome === file.file_nome ? file.nome : file.nome + '.' + file.format;
                            fileLink.setAttribute('download', nomeDownloadFile);
                            document.body.appendChild(fileLink);

                            fileLink.click();
                        });
                    });
                }
            },

            sizeHandler(data) {
                let ret = null;
                if (data > 1 && data < 1000) {
                    ret = Math.trunc(data) + ' Byte';
                } else if ((data / 1024) > 1 && (data / 1024) < 1000) {
                    ret = Math.trunc((data / 1024)) + ' KB';
                } else if ((data / Math.pow(1024, 2)) > 1 && (data / Math.pow(1024, 2)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 2))) + ' MB';
                } else if ((data / Math.pow(1024, 3)) > 1 && (data / Math.pow(1024, 3)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 3))) + ' GB';
                } else if ((data / Math.pow(1024, 4)) > 1 && (data / Math.pow(1024, 4)) < 1000) {
                    ret = Math.trunc((data / Math.pow(1024, 4))) + ' TB';
                }
                return ret;
            },
        }
    }
</script>

<style scoped>

</style>