<template>
    <b-modal id="modal-delete-file" size="lg" body-class="p-0" hide-footer hide-header static>
        <div class="block block-rounded block-themed block-transparent mb-0">
            <b-form @submit.prevent="onSubmit">
                <div class="block-header">
                    <h3 class="block-title">Elimina {{file.nome}}</h3>
                    <div class="block-options">
                        <button type="button" class="btn-block-option"
                                @click="$bvModal.hide('modal-delete-file')">
                            <i class="fa fa-fw fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="block-content font-size-sm">

                    <div v-if="this.$route.name === 'Trash'">
                        <p class="font-size-h5 text-danger">Attenzione!</p>
                        <p class="font-size-h6">Questa è una cancellazione definitiva</p>
                    </div>
                    <div v-else>
                        <p class="font-size-h6">Puoi sempre ritrovare questo file nel cestino</p>
                    </div>

                    <b-form-group label="Conferma eliminazione" label-for="example-text-input">
                        <b-form-input v-model="$v.form.confirmation.$model"
                                      :state="$v.form.confirmation.$dirty ? !$v.form.confirmation.$error : null"
                                      :id="'confirm'+this.file.id" placeholder="Digita Elimina">
                        </b-form-input>
                        <b-form-invalid-feedback :id="'confirm'+this.file.id">
                            Digita elimina per confermare la cancellazione
                        </b-form-invalid-feedback>
                    </b-form-group>

                </div>

                <div class="block-content block-content-full text-right border-top">
                    <b-button variant="alt-primary" class="mr-1"
                              @click="$bvModal.hide('modal-delete-file')">Annulla
                    </b-button>
                    <b-button type="submit" variant="danger">Elimina</b-button>
                </div>

            </b-form>
        </div>
    </b-modal>
</template>

<script>

    import {required, minLength} from "vuelidate/lib/validators";
    import {validationMixin} from "vuelidate";
    import axios from "axios";
    import {EventBus} from "@/main";
    import VueSweetalert2 from "vue-sweetalert2";
    import Vue from "vue";

    const options = {
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-success m-1',
            cancelButton: 'btn btn-danger m-1',
            input: 'form-control'
        }
    }

    // Register Vue SweetAlert2 with custom options
    Vue.use(VueSweetalert2, options)

    export default {
        mixins: [validationMixin],
        name: "ModalDeleteFile",

        props: {
            file: {
                required: true,
                minLength: minLength(7)
            },
        },

        data() {
            return {
                form: {
                    confirmation: null,
                },
                checkconfirmation: 'elimina',

            }
        },

        validations: {
            form: {
                confirmation: {
                    required,
                },
            },
        },

        methods: {
            onSubmit() {
                this.$v.form.$touch()

                if (this.form.confirmation !== this.checkconfirmation) {
                    this.$v.form.$anyError = true;
                }

                if (this.$v.form.$anyError || (this.form.confirmation !== this.checkconfirmation)) {
                    return
                }

                // Form submit logic
                var url = this.$store.state.server.ip + 'tree/' + this.file.id;
                var token = localStorage.getItem('token');

                axios.delete(url, {
                    params: {
                        'definitive' : this.$route.name === 'Trash'
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }, {
                    'id': this.file.id
                })
                    .then(() => {
                        EventBus.$emit('deletedFile', this.file.parent_id);
                        this.swalSuccess();
                        this.$bvModal.hide('modal-delete-file')
                        this.$bvModal.hide('modal-edit-file-' + this.file.id)
                    })
                    .catch(error => {
                        console.log(error.response.data)
                        this.swalError();
                    });
            },

            swalSuccess() {
                this.$swal('Success', 'File eliminato', 'success')
            },

            swalError() {
                this.$swal('Oops...', 'Problemi con l\'eliminazione', 'error')
            },

        },


    }
</script>

<style scoped>

</style>