<template>
    <b-modal :id="'modal-edit-file-'+file.id" size="lg" body-class="p-0" hide-footer hide-header static>
        <div class="block block-rounded block-themed block-transparent mb-0">
            <b-form @submit.prevent="onSubmit">
                <div class="block-header">
                    <h3 class="block-title">Modifica file</h3>
                    <div class="block-options">
                        <button type="button" class="btn-block-option"
                                @click="$bvModal.hide('modal-edit-file-'+file.id)">
                            <i class="fa fa-fw fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="block-content font-size-sm">
                    <b-form-group label="Nome" label-for="example-text-input">
                        <b-form-input v-model="$v.form.nome.$model"
                                      :state="$v.form.nome.$dirty ? !$v.form.nome.$error : null"
                                      :id="'nome'+this.file.id" placeholder="Nome file">
                        </b-form-input>
                        <b-form-invalid-feedback :id="'nome'+this.file.id">
                            Inserisci il nome della cartella
                        </b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="block-content block-content-full border-top">
                    <b-row>
                        <b-col class="text-left">
                            <a class="text-danger"
                               href="javascript:void(0)"
                               @click="$bvModal.show('modal-delete-file')">Elimina
                            </a>
                        </b-col>
                        <b-col class="text-right">
                            <b-button variant="alt-primary" class="mr-1"
                                      @click="$bvModal.hide('modal-edit-file-'+file.id)">Annulla
                            </b-button>
                            <b-button type="submit" variant="primary">Modifica</b-button>
                        </b-col>
                    </b-row>


                    <modal-delete-file :file="file">
                    </modal-delete-file>

                </div>
            </b-form>
        </div>
    </b-modal>
</template>

<script>

    import {required} from 'vuelidate/lib/validators'
    import {validationMixin} from "vuelidate";
    import axios from "axios";
    import {EventBus} from "@/main";
    import VueSweetalert2 from "vue-sweetalert2";
    import Vue from "vue";
    import ModalDeleteFile from "@/views/Tree/File/ModalDeleteFile";


    const options = {
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-success m-1',
            cancelButton: 'btn btn-danger m-1',
            input: 'form-control'
        }
    }

    // Register Vue SweetAlert2 with custom options
    Vue.use(VueSweetalert2, options)

    export default {
        components: {ModalDeleteFile},
        mixins: [validationMixin],
        name: "ModalEditFile",

        props: {
            file: {
                required: true,
            }
        },

        data() {
            return {
                form: {
                    nome: this.file.nome,
                },

            }
        },

        validations: {
            form: {
                nome: {
                    required,
                },
            },
        },

        methods: {
            onSubmit() {
                this.$v.form.$touch()

                if (this.$v.form.$anyError) {
                    return
                }

                // Form submit logic
                var url = this.$store.state.server.ip + 'tree/' + this.file.id;
                var token = localStorage.getItem('token');

                axios.put(url, {
                    'file': true,
                    'nome': this.removeSpecialCharacter(this.form.nome),
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(() => {
                        EventBus.$emit('editedFile', this.file.parent_id);
                        this.swalSuccess();
                        this.$bvModal.hide('modal-edit-file-' + this.file.id)
                    })
                    .catch(error => {
                        console.log(error.response.data)
                        this.swalError();
                    });

            },

            swalSuccess() {
                this.$swal('Success', 'Salavataggio completato', 'success')
            },

            swalError() {
                this.$swal('Oops...', 'Problemi nel salvataggio', 'error')
            },

            removeSpecialCharacter(str) {
                return str.replace(/(?!\w|\s)/g, '')
                    .replace(/\s+/g, ' ')
                    .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');

            }
        }
    }
</script>

<style scoped>

</style>