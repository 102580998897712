/*
 * Main and demo navigation arrays
 */

export default {
    'main': [
        {
            name: 'Dashboard',
            to: '/dashboard',
            icon: 'si si-speedometer'
        },
    ]
}
