<template>
    <b-modal id="modal-search-user" size="lg" body-class="p-0" hide-footer hide-header>
        <div class="block block-rounded block-themed block-transparent mb-0">
            <b-form @submit.prevent="">
                <div class="block-header bg-light">
                    <h3 class="block-title">Cerca</h3>
                    <div class="block-options">
                        <b-button type="button" variant="dark" size="sm" class="btn-block"
                                  @click="$bvModal.hide('modal-search-user')">
                            <i class="fa fa-fw fa-times"></i>
                        </b-button>
                    </div>
                </div>
                <div class="block-content font-size-sm">

                    <div class="form-group">
                        <b-input-group>
                            <b-form-input class="form-control-alt" placeholder="Nome, cognome o email dell'utente" v-model="searchWord"
                                          @keydown="search"></b-form-input>
                            <b-input-group-append>
                                <b-button variant="alt-dark" @click="search">
                                    <i class="fa fa-search mr-1"></i> Search
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>

                    <template v-if="users && Object.keys(users).length !== 0">
                        <ul class="list-group">
                            <li v-for="user in users" :key="user.id" class="list-group-item">
                                <b-row>
                                    <b-col class="col-lg-4 col-md-4 col-sm-12">

                                        <a>
                                            <i class="fa fa-fw fa-user"></i>
                                            {{user.nome}}
                                            {{user.cognome}}
                                        </a>

                                    </b-col>
                                    <b-col class="col-lg-4 col-md-4 col-sm-12">

                                        <a>
                                            <i class="fa fa-fw fa-at"></i>
                                            {{user.email}}
                                        </a>

                                    </b-col>
                                    <b-col class="text-right col-lg-4 col-md-4 col-sm-12">
                                        <b-button size="sm" variant="alt-secondary" class="mr-1"
                                                  @click="$bvModal.show('modal-edit-user-'+user.id)">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </b-button>
                                    </b-col>
                                </b-row>

                                <!-- Modal Edit User -->
                                <modal-edit-user :user="user">
                                </modal-edit-user>
                                <!-- END Modal Edit User -->

                            </li>
                        </ul>
                        <p></p>
                    </template>

                </div>
            </b-form>
        </div>
    </b-modal>
</template>

<script>
    import axios from "axios";
    import {EventBus} from "@/main";
    import ModalEditUser from "@/views/Users/ModalEditUser";

    export default {
        name: "ModalSearchUser",
        components: {ModalEditUser},
        data() {
            return {
                searchWord: '',
                users: null
            }
        },

        mounted() {

            EventBus.$on(["createdUser", "editedUser"], () => {
                this.search();
            });

        },

        methods: {
            search() {
                if (this.searchWord.length >= 2) {
                    var url = this.$store.state.server.ip + 'users';
                    var token = localStorage.getItem('token');

                    return axios.get(url,
                        {
                            params: {
                                'search': this.searchWord,
                            },

                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        })
                        .then(response => {
                            this.users = response.data.data;
                        })
                        .catch(error => {
                            console.log(error.response)
                        });
                } else {
                    this.files = null;
                }
            },
        }
    }
</script>

<style scoped>

</style>