<template>
    <base-layout :layout-classes="layoutClasses"></base-layout>
</template>

<script>
    import BaseLayout from '../Base'

    export default {
        name: 'LayoutBackend',
        components: {
            BaseLayout
        },
        data() {
            return {
                user: null,
                // Override and set custom CSS classes to the container of each base layout element
                layoutClasses: {
                    sideOverlay: '',
                    sidebar: '',
                    header: '',
                    footer: ''
                }
            }
        },
        created() {

            if (localStorage.getItem('user')) {
                this.user = JSON.parse(localStorage.getItem('user'));
                this.$store.commit('sidebarMini', { mode: 'on' })
                if (this.user.tipo === 'ADMIN') {
                    this.$store.commit('sidebar', {mode: 'open'})
                } else {
                    this.$store.commit('sidebar', {mode: 'close'})
                }
            }
            // Set default elements for this layout
            this.$store.commit('setLayout', {
                header: true,
                sidebar: false,
                sideOverlay: true,
                footer: true,
            })

            // Set various template options
            this.$store.commit('headerStyle', {mode: 'light'})
            this.$store.commit('mainContent', {mode: 'full'})
        }
    }
</script>
