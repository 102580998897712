<template>
    <!-- Header -->
    <header id="page-header">
        <slot>
            <!-- Header Content -->
            <div class="content-header">
                <!-- Left Section -->
                <div class="d-flex align-items-center">
                    <!-- Toggle Sidebar -->
                    <base-layout-modifier action="sidebarToggle" size="sm" variant="dual" class="mr-2 d-lg-none">
                        <i class="fa fa-fw fa-bars"></i>
                    </base-layout-modifier>
                    <!-- END Toggle Sidebar -->


                </div>
                <!-- END Left Section -->

                <!-- Right Section -->
                <div class="d-flex align-items-center">

                    <b-button v-if="this.$route.name === 'Utenti' && user.tipo === 'ADMIN'" variant="alt-success"
                              class="mr-1" size="sm"
                              v-click-ripple @click="$bvModal.show('modal-create-user')">
                        <i class="fa fa-user-plus mr-1"></i>Crea un nuovo utente
                    </b-button>

                    <template v-if="this.$route.name === 'Dashboard'">
                        <b-button variant="light" class="mr-1" size="sm"
                                  v-click-ripple @click="$bvModal.show('modal-search-file')">
                            <i class="fa fa-search mr-1"></i>Cerca
                        </b-button>

                        <modal-search-file>
                        </modal-search-file>

                    </template>
                    <template v-else-if="this.$route.name === 'Utenti'">
                        <b-button variant="light" class="mr-1" size="sm"
                                  v-click-ripple @click="$bvModal.show('modal-search-user')">
                            <i class="fa fa-search mr-1"></i>Cerca
                        </b-button>

                        <modal-search-user>
                        </modal-search-user>

                    </template>

                    <!-- User Dropdown -->
                    <b-dropdown size="sm" variant="dual" class="d-inline-block ml-2"
                                menu-class="p-0 border-0 dropdown-menu-md" right no-caret ref="oneDropdownDefaultUser">
                        <template #button-content>
                            <div class="d-flex align-items-center">
                                <img class="rounded-circle" src="img/avatars/avatar10.jpg" alt="Header Avatar"
                                     style="width: 21px;">
                                <span class="d-none d-sm-inline-block ml-2">{{user.nome}}</span>
                                <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block ml-1 mt-1"></i>
                            </div>
                        </template>
                        <li @click="$refs.oneDropdownDefaultUser.hide(true)">
                            <div class="p-3 text-center bg-primary-dark rounded-top">
                                <img class="img-avatar img-avatar48 img-avatar-thumb" src="img/avatars/avatar10.jpg"
                                     alt="Avatar">
                                <p class="mt-2 mb-0 text-white font-w500">{{user.nome}} {{user.cognome}}</p>
                                <p class="mb-0 text-white-50 font-size-sm">{{user.tipo.toLowerCase()}}</p>
                            </div>
                            <div class="p-2">

                                <template v-if="user.tipo === 'ADMIN'">
                                    <a class="dropdown-item d-flex align-items-center justify-content-between"
                                       href="javascript:void(0)" @click="dashboard">
                                        <i class="fa fa-fw fa-home"></i>
                                        <span class="font-size-sm font-w500">Dashboard</span>
                                    </a>
                                    <a class="dropdown-item d-flex align-items-center justify-content-between"
                                       href="javascript:void(0)" @click="utenti">
                                        <i class="fa fa-fw fa-address-book"></i>
                                        <span class="font-size-sm font-w500">Gestione Utenti</span>
                                    </a>
                                    <a class="dropdown-item d-flex align-items-center justify-content-between"
                                       href="javascript:void(0)" @click="cestino">
                                        <i class="fa fa-fw fa-trash-alt"></i>
                                        <span v-if="deletedFiles && deletedFiles.length > 0" class="badge badge-pill badge-primary mr-5">{{deletedFiles.length}}</span>
                                        <span class="font-size-sm font-w500">Cestino</span>
                                    </a>
                                    <div role="separator" class="dropdown-divider"></div>
                                </template>
                                <a class="dropdown-item d-flex align-items-center justify-content-between"
                                   href="javascript:void(0)" @click="logout">
                                    <i class="fa fa-fw fa-arrow-left"></i>
                                    <span class="font-size-sm font-w500">Esci</span>
                                </a>

                            </div>
                        </li>
                    </b-dropdown>
                    <!-- END User Dropdown -->

                </div>
                <!-- END Right Section -->
            </div>
            <!-- END Header Content -->

            <!-- Header Search -->
            <div id="page-header-search" class="overlay-header bg-white"
                 :class="{ 'show': $store.state.settings.headerSearch }"
                 @keydown.esc="() => $store.commit('headerSearch', { mode: 'off' })">
                <div class="content-header">
                    <b-form @submit.stop.prevent="onSubmit" class="w-100">
                        <b-input-group>
                            <b-input-group-prepend>
                                <base-layout-modifier action="headerSearchOff" variant="alt-danger">
                                    <i class="fa fa-fw fa-times-circle"></i>
                                </base-layout-modifier>
                            </b-input-group-prepend>
                            <b-form-input placeholder="Search or hit ESC.." v-model="baseSearchTerm"></b-form-input>
                        </b-input-group>
                    </b-form>
                </div>
            </div>
            <!-- END Header Search -->

            <!-- Header Loader -->
            <div id="page-header-loader" class="overlay-header bg-white"
                 :class="{ 'show': $store.state.settings.headerLoader }">
                <div class="content-header">
                    <div class="w-100 text-center">
                        <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                    </div>
                </div>
            </div>
            <!-- END Header Loader -->
        </slot>
    </header>
    <!-- END Header -->
</template>

<script>

    import axios from "axios";
    import ModalSearchFile from "@/views/Tree/File/ModalSearchFile";
    import ModalSearchUser from "@/views/Users/ModalSearchUser";
    import {EventBus} from "@/main";

    export default {
        name: 'BaseHeader',
        components: {ModalSearchUser, ModalSearchFile},
        props: {
            classes: String
        },
        data() {
            return {
                baseSearchTerm: '',
                user: null,
                deletedFiles: null,
            }
        },
        methods: {
            onSubmit() {
                this.$router.push('/backend/pages/generic/search?' + this.baseSearchTerm)
            },
            eventHeaderSearch(event) {
                // When ESCAPE key is hit close the header search section
                if (event.which === 27) {
                    event.preventDefault()
                    this.$store.commit('headerSearch', {mode: 'off'})
                }
            },

            logout() {

                var url = this.$store.state.server.ip + 'logout';
                var token = localStorage.getItem('token');
                return axios.post(url, {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(() => {
                        localStorage.clear()
                        this.$router.push('/')
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });

            },

            loadDeletedFiles() {
                var url = this.$store.state.server.ip + 'tree';
                var token = localStorage.getItem('token');

                return axios.get(url,
                    {
                        params: {
                            'trash': true,
                        },
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        this.deletedFiles = response.data.data;
                    })
                    .catch(error => {
                        console.log(error.response)
                    });

            },

            cestino() {
                this.$router.push('/cestino').catch(()=>{});
            },

            utenti() {
                this.$router.push('/utenti').catch(()=>{});
            },

            dashboard() {
                this.$router.push('/');
            },

        },

        created() {
            if (localStorage.getItem('user')) {
                this.user = JSON.parse(localStorage.getItem('user'));
            } else {
                localStorage.clear()
                this.$router.push('/')
            }

            if(this.user.tipo === 'ADMIN'){
                this.loadDeletedFiles();
            }
        },

        mounted() {
            EventBus.$on(["restoredFileFolder", "deletedFolder", "deletedFile"], () => {
                this.loadDeletedFiles();
            });
            document.addEventListener('keydown', this.eventHeaderSearch)
        },
        destroyed() {
            document.removeEventListener('keydown', this.eventHeaderSearch)
        },

    }
</script>
