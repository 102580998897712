/*
 * Default Router
 */

// Vue and Vue Router
import Vue from 'vue'
import Router from 'vue-router'


// Main layouts
import LayoutBackend from '@/layouts/variations/Backend.vue'
//import LayoutBackendBoxed from '@/layouts/variations/BackendBoxed.vue'
import LayoutSimple from '@/layouts/variations/Simple.vue'

// Register Vue Router
Vue.use(Router)

// Frontend: Landing
// const Landing = () => import("@/views/Landing.vue")

// Backend: General
const Dashboard = () => import(/* webpackChunkName: "pages-dashboard", webpackPrefetch: true */"@/views/Dashboard.vue")

// Pages: Auth
const Login = () => import(/* webpackChunkName: "auth-signin" */"@/views/Login/Login.vue")
const AutoLogin = () => import(/* webpackChunkName: "auth-signin" */"@/views/Login/AutoLogin.vue")

//Pages: Gestione Utenti
const Users = () => import(/* webpackChunkName: "pages-dashboard", webpackPrefetch: true */"@/views/Users.vue")

//Pages: Trash
const Trash = () => import(/* webpackChunkName: "pages-dashboard", webpackPrefetch: true */"@/views/Trash.vue")

//Pages: Disclaimer
const Disclaimer = () => import(/* webpackChunkName: "pages-dashboard", webpackPrefetch: true */"@/views/Disclaimer.vue")

// Router Configuration
const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    linkExactActiveClass: '',
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            redirect: '/login',
            component: LayoutSimple,
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    component: Login
                },
                {
                    path: 'autologin',
                    name: 'Autologin',
                    component: AutoLogin
                },
            ]
        },
        {
            path: '/',
            redirect: '/dashboard',
            component: LayoutBackend,
            meta: {requireAuth: true},
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard
                },
                {
                    path: 'utenti',
                    name: 'Utenti',
                    meta: {requireAdmin: true},
                    component: Users
                },
                {
                    path: 'cestino',
                    name: 'Trash',
                    meta: {requireAdmin: true},
                    component: Trash
                },
                {
                    path: 'disclaimer',
                    name: 'Disclaimer',
                    component: Disclaimer
                },
            ]
        },
    ]
});

router.beforeEach((to, from, next) => {
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (!localStorage.getItem('user')) {
            next({
                name: 'Login'
            });
        } else {
            if(to.matched.some(record => record.meta.requireAdmin) && loggedUser.tipo !== 'ADMIN') {
                next({
                    name: 'Dashboard'
                });
            } else {
                next();
            }
        }
    } else {
        next();
    }
})


export default router;
